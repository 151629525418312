
.carrousel {
    margin-bottom: 30px;

    position: relative;

    width: 100%;
    height: 415px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    .slide {
        object-fit: cover;

        width: 100%;
        height: 100%;
        border-radius: 25px;

        user-select: none;
        -webkit-user-drag: none;
    }

    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
    }

    .arrow-left {
        left: 10px;
    }

    .arrow-right {
        right: 10px;
    }

    .indicator {
        position: absolute;
        bottom: 10px;

        color: white;
    }

    .hidden {
        display: none;
    }

    .reverse {
        transform: rotate(180deg);
    }
}

@media screen and (max-width: 800px) {
    .carrousel {
        margin-bottom: 15px;
        height: 255px;

        .indicator {
            display: none;
        }
    }
}
