
// Logement Specific
.logement-details {
    display: flex;
    gap: 80px;
    margin-bottom: 50px;
}
.logement-details > div {
    flex: 1 1 0;
}
.logementContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}


// - Host + Rating
.host-rating {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.logement {
    h1 {
        line-height: 142.6%;
        font-size: 36px;
        font-weight: 500;
    }

    h2 {
        font-weight: 500;
    }

    .dropdown {
        img {
            height: 18px;
        }
    
        p {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 800px) {
    .logement-details {
        flex-direction: column;
        gap: 20px;
    }

    .logementContainer {
        flex-direction: column;
    }
    
    .host-rating {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }

    .logement {
        h1 {
            font-size: 18px;
        }

        h2 {
            font-size: 14px;
            padding-top: 5px;
        }

        .dropdown {
            img {
                height: 13px;
            }
            
            p {
                font-size: 13px;
            }
        }
    }
}
