
.home {
    gap: 50px;
    display: flex;
    flex-direction: column;
}

// Logements
.logements {
    background: #F6F6F6;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    gap: 60px;
    padding: 50px;
    flex-wrap: wrap;
    padding-bottom: 30px;

    a {
        position: relative;
        aspect-ratio: 1 / 1;
        background-color: var(--color-main);
        border-radius: 25px;
        overflow: hidden;

        width: calc(100% / 3 - 40px);
        // height: 340px;

        article {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            h2 {
                padding: 20px;
                bottom: 0;
                left: 0;
    
                position: absolute;
                color: white;
    
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .home {
        gap: 20px;
    }

    // Logements
    .logements {
        gap: 20px;
        background-color: unset;
        padding: 0;
        padding-bottom: 30px;



        a {
            aspect-ratio: 335 / 255;
            width: calc(100%);
            // height: 340px;
    
            article {
                width: 100%;
                height: 100%;

                img {

                }
        
                h2 {
    
                }    
            }
        }
    }
}
