
nav {
    display: flex;
    gap: 20px;

    a {
        font-size: 24px;
        color: black;
        font-weight: 500;
        text-decoration: none;
    }
    a.active {
        text-decoration: underline;
    }
}

@media screen and (max-width: 800px) {
    nav {
        a {
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}
