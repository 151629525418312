
header {
    padding: 20px 100px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 210px;
    }

}

@media screen and (max-width: 800px) {
    header {
        padding: 20px 20px;

        img {
            width: 145px;
        }
    }
}
