
.banner {
    width: 100%;
    border-radius: 25px;
    overflow: hidden;

    position: relative;

    height: 225px;

    img {
        position: absolute;
        width: 100%;
        height: 100%;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        object-fit: cover;
    }

    div {
        width: 100%;
        height: 100%;

        position: absolute;

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.6);

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 48px;
            color: white;
            text-align: center;
        }
    }

    div.light {
        background-color: rgba(0, 0, 0, 0.3); 
    }
}

@media screen and (max-width: 800px) {
    .banner {
        height: 110px;

        div {    
            h1 {
                font-size: 24px;
                text-align: left;
                padding: 20px 20px;
            }
        }
    }
}
