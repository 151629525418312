
footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 50px 0 30px 0;

    img {
        width: 120px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(168deg) brightness(105%) contrast(107%);
    }

    p {
        color: white;
        font-size: 24px;
        font-weight: 500;
    }
}

@media screen and (max-width: 800px) {
    footer {
        padding: 50px 0;
        gap: 25px;

        p {
            width: 130px;
            font-size: 12px;
            padding: 0 10px;
            text-align: center;
        }
    }
}
